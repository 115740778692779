import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { onlyBaseUrl } from "../UrlConfig/BaseUrl";
import { tokenVerify } from "../UrlConfig/TokenVerify";

export const studentApi = createApi({
  reducerPath: "studentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: onlyBaseUrl,
    prepareHeaders: tokenVerify,
  }),
  endpoints: () => ({}),
});

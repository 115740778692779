import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl, onlyBaseUrl } from "../UrlConfig/BaseUrl";
import { tokenVerify } from "../UrlConfig/TokenVerify";

export const admissionManagerApi = createApi({
  reducerPath: "admissionManagerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: onlyBaseUrl,
    prepareHeaders: tokenVerify,
  }),
  tagTypes: ["Cash_finance", "Nested_Criteria_Detail"],
  endpoints: () => ({}),
});

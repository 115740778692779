import React from "react";
import AllRoute from "./Routes";
import "./App.css";
import { useEffect } from "react";
import { GlobalDebug } from "./utils/console";
import { systemMeasure } from "./optimized/Utils/Functions/systemMeasure";
function App() {
  const { height } = systemMeasure();
  useEffect(() => {
    GlobalDebug(false);
  }, []);

  return (
    <div
      className="dynamic-body"
      style={{
        "--body-min-height": `${height}px`,
      }}
    >
      <AllRoute />
    </div>
  );
}

export default App;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { automateBaseUrl } from "../UrlConfig/BaseUrl";
import { tokenVerify } from "../UrlConfig/TokenVerify";

export const instituteAutomateManagerApi = createApi({
  reducerPath: "instituteAutomateManagerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: automateBaseUrl,
    prepareHeaders: tokenVerify,
  }),
  // tagTypes: ["Cash_finance"],
  endpoints: () => ({}),
});

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrlV1 } from "../UrlConfig/BaseUrl";
import { tokenVerify } from "../UrlConfig/TokenVerify";

export const libraryManagerApi = createApi({
  reducerPath: "libraryManagerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrlV1,
    prepareHeaders: tokenVerify,
  }),
  // tagTypes:[""]
  endpoints: () => ({}),
});

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { onlyBaseUrl } from "../UrlConfig/BaseUrl";
import { tokenVerify } from "../UrlConfig/TokenVerify";

export const classManagerApi = createApi({
  reducerPath: "classManagerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: onlyBaseUrl,
    prepareHeaders: tokenVerify,
  }),
  tagTypes: ["Tab_Manage"],
  endpoints: () => ({}),
});
// providesTags: ["Finance_Internal_Fees"],
// invalidatesTags: ["Finance_Internal_Fees"],

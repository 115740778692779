import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authenticationApi } from "../../services/Authentication/authenticationApi";
import { dashboardApi } from "../../services/Dashboard/dashboardApi";
import { instituteStaffStudentApi } from "../../services/InstituteStaffStudent/instituteStaffStudentApi";
import { qvipleDynamicApi } from "../../services/qvipleDynamicApi";
import { superDynamicApi } from "../../services/superDynamicApi";
import { chatAPI } from "../../services/chatAPI";
import { financeDynamicApi } from "../../services/financeDynamicApi";
import { paymentConfigureDynamicApi } from "../../services/RazorPay/paymentConfigureDynamicApi";
import { joinDynamicApi } from "../../services/joining/joinDynamicApi";
import { sportArtDynamicApi } from "../../services/sports_and_arts/sportArtDynamicApi";
import { switchAccountApi } from "../../services/switchAccount/switchAccountApi";
import { CLTApi } from "../../services/CLTApi";
import { instituteDepartmentApi } from "../../services/InstituteDepartment/instituteDepartmentApi";
import { postApi } from "../../services/Post/postApi";
import { imageApi } from "../../services/imageApi";
import { searchApi } from "../../services/searchApi";
import { attendanceApi } from "../../services/attendanceApi";
import { settingApi } from "../../services/Setting/settingApi";
import { departmentDynamicApi } from "../../services/staff_department/departmentDynamicApi";
import { classDynamicApi } from "../../services/staff_department/classDynamicApi";
import { subjectDynamicApi } from "../../services/staff_department/subjectDynamicApi";
import { plateformDynamicApi } from "../../services/plateform/plateformDynamicApi";
import { libraryDynamicApi } from "../../services/library/libraryDynamicApi";
import { eventDynamicApi } from "../../services/event/eventDynamicApi";
import { careerDynamicApi } from "../../services/career/careerDynamicApi";
import { admissionNewApi } from "../../services/admission/admissionNewApi";
import { exportDataDynamicApi } from "../../services/ExportData/exportDataDynamicApi";
import { studentMemberDynamicApi } from "../../services/student_members_ab/studentMemberDynamicApi";
import { activityDynamicApi } from "../../services/staff_department/activityDynamicApi";
import { alumniDynamicApi } from "../../services/alumni/alumniDynamicApi";
import idChangeReducer from "./department-slice";
import authChangeReducer from "./authenticate-slice";
import postChangeReducer from "./post-slice";
import chatChangeReducer from "./chat-slice";
import financeChangeReducer from "./finance-slice";
import profileChangeReducer from "./profile-slice";
import adminChangeReducer from "./superAdmin-slice";
import admissionChangeReducer from "./admission-slice";
import sportsArtChangeReducer from "./sports-and-arts-slice";
import libraryChangeReducer from "./library-slice";
import eventAdminChangeReducer from "./event-slice";
import careerChangeReducer from "./career-slice";
import tenderChangeReducer from "./tender-slice";
import alumniChangeReducer from "./alumni-slice";
import { hostelApi } from "../../services/staff_department/hostel/hostelApi";
import { transportApi } from "../../services/staff_department/transport/transportApi";
import attendanceStoreReducer from "./attendance-slice";

// here start new optimized code import

import { authApi } from "../../optimized/Services/Auth/authApi";
import { userMemberApi } from "../../optimized/Services/UserMember/userMemberApi";
import { financeManagerApi } from "../../optimized/Services/FinanceManager/financeManagerApi";
import { admissionManagerApi } from "../../optimized/Services/AdmissionManager/admissionManagerApi";
import { studentProfileApi } from "../../optimized/Services/StudentProfile/studentProfileApi";
import { lmsManagerApi } from "../../optimized/Services/LmsManager/lmsManagerApi";
import { instituteFeedbackApi } from "../../optimized/Services/InstituteFeedback/instituteFeedbackApi";
import { libraryManagerApi } from "../../optimized/Services/LibraryManager/libraryManagerApi";
import { departmentManagerApi } from "../../optimized/Services/DepartmentManager/departmentManagerApi";
import { mentorManagerApi } from "../../optimized/Services/MentorManager/mentorManagerApi";
import { classManagerApi } from "../../optimized/Services/ClassManager/classManagerApi";
import { subjectManagerApi } from "../../optimized/Services/SubjectManager/subjectManagerApi";
import { staffApi } from "../../optimized/Services/StaffManager/staffApi";
import { studentApi } from "../../optimized/Services/StudentManager/studentApi";
import { instituteAutomateManagerApi } from "../../optimized/Services/InstituteAutomate/instituteAutomateManagerApi";
import { storeManagerApi } from "../../optimized/Services/StoreManager/storeManagerApi";
import { instituteStudentApi } from "../../optimized/Services/InstituteStudent/instituteStudentApi";
import { payrollManagerApi } from "../../optimized/Services/PayrollManager/payrollManagerApi";
import { siteManagerApi } from "../../optimized/Services/SiteManager/siteManagerApi";
import { instituteStaffApi } from "../../optimized/Services/InstituteStaff/instituteStaffApi";
import { academicDepartmentManagerApi } from "../../optimized/Services/AcademicDepartmentManager/academicDepartmentManagerApi";
import { certificateManagerApi } from "../../optimized/Services/CertificateManager/certificateManagerApi";
import { hostelManagerApi } from "../../optimized/Services/HostelManager/hostelManagerApi";
import { guideManagerApi } from "../../optimized/Services/GuideManager/guideManagerApi";
import { inoutwardManagerApi } from "../../optimized/Services/InOutwardManager/inoutwardManagerApi";

// import { userMemberApi } from "@/Services/UserMember/userMemberApi";
// import { financeManagerApi } from "@/Services/FinanceManager/financeManagerApi";
// import { authApi } from "@/Services/Auth/authApi";

export const store = configureStore({
  reducer: {
    [authenticationApi.reducerPath]: authenticationApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [instituteStaffStudentApi.reducerPath]: instituteStaffStudentApi.reducer,
    [qvipleDynamicApi.reducerPath]: qvipleDynamicApi.reducer,
    [superDynamicApi.reducerPath]: superDynamicApi.reducer,
    [chatAPI.reducerPath]: chatAPI.reducer,
    [financeDynamicApi.reducerPath]: financeDynamicApi.reducer,
    [paymentConfigureDynamicApi.reducerPath]:
      paymentConfigureDynamicApi.reducer,
    [CLTApi.reducerPath]: CLTApi.reducer,
    [instituteDepartmentApi.reducerPath]: instituteDepartmentApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
    [imageApi.reducerPath]: imageApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [attendanceApi.reducerPath]: attendanceApi.reducer,
    [settingApi.reducerPath]: settingApi.reducer,
    [joinDynamicApi.reducerPath]: joinDynamicApi.reducer,
    [sportArtDynamicApi.reducerPath]: sportArtDynamicApi.reducer,
    [exportDataDynamicApi.reducerPath]: exportDataDynamicApi.reducer,
    [switchAccountApi.reducerPath]: switchAccountApi.reducer,
    [departmentDynamicApi.reducerPath]: departmentDynamicApi.reducer,
    [classDynamicApi.reducerPath]: classDynamicApi.reducer,
    [subjectDynamicApi.reducerPath]: subjectDynamicApi.reducer,
    [plateformDynamicApi.reducerPath]: plateformDynamicApi.reducer,
    [libraryDynamicApi.reducerPath]: libraryDynamicApi.reducer,
    [eventDynamicApi.reducerPath]: eventDynamicApi.reducer,
    [careerDynamicApi.reducerPath]: careerDynamicApi.reducer,
    [admissionNewApi.reducerPath]: admissionNewApi.reducer,
    [studentMemberDynamicApi.reducerPath]: studentMemberDynamicApi.reducer,
    [activityDynamicApi.reducerPath]: activityDynamicApi.reducer,
    [alumniDynamicApi.reducerPath]: alumniDynamicApi.reducer,
    [hostelApi.reducerPath]: hostelApi.reducer,
    [transportApi.reducerPath]: transportApi.reducer,
    idChange: idChangeReducer,
    authChange: authChangeReducer,
    postChange: postChangeReducer,
    chatChange: chatChangeReducer,
    financeChange: financeChangeReducer,
    profileChange: profileChangeReducer,
    adminChange: adminChangeReducer,
    admissionChange: admissionChangeReducer,
    sportsArtChange: sportsArtChangeReducer,
    libraryChange: libraryChangeReducer,
    eventAdminChange: eventAdminChangeReducer,
    careerChange: careerChangeReducer,
    tenderChange: tenderChangeReducer,
    alumniChange: alumniChangeReducer,
    attendanceStore: attendanceStoreReducer,

    // here start new optimized code import
    [authApi.reducerPath]: authApi.reducer,
    [userMemberApi.reducerPath]: userMemberApi.reducer,
    [financeManagerApi.reducerPath]: financeManagerApi.reducer,
    [admissionManagerApi.reducerPath]: admissionManagerApi.reducer,
    [studentProfileApi.reducerPath]: studentProfileApi.reducer,
    [lmsManagerApi.reducerPath]: lmsManagerApi.reducer,
    [instituteFeedbackApi.reducerPath]: instituteFeedbackApi.reducer,
    [libraryManagerApi.reducerPath]: libraryManagerApi.reducer,
    [departmentManagerApi.reducerPath]: departmentManagerApi.reducer,
    [mentorManagerApi.reducerPath]: mentorManagerApi.reducer,
    [classManagerApi.reducerPath]: classManagerApi.reducer,
    [subjectManagerApi.reducerPath]: subjectManagerApi.reducer,
    [staffApi.reducerPath]: staffApi.reducer,
    [studentApi.reducerPath]: studentApi.reducer,
    [instituteAutomateManagerApi.reducerPath]:
      instituteAutomateManagerApi.reducer,
    [storeManagerApi.reducerPath]: storeManagerApi.reducer,
    [instituteStudentApi.reducerPath]: instituteStudentApi.reducer,
    [payrollManagerApi.reducerPath]: payrollManagerApi.reducer,
    [siteManagerApi.reducerPath]: siteManagerApi.reducer,
    [instituteStaffApi.reducerPath]: instituteStaffApi.reducer,
    [academicDepartmentManagerApi.reducerPath]:
      academicDepartmentManagerApi.reducer,
    [certificateManagerApi.reducerPath]: certificateManagerApi.reducer,
    [hostelManagerApi.reducerPath]: hostelManagerApi.reducer,
    [guideManagerApi.reducerPath]: guideManagerApi.reducer,
    [inoutwardManagerApi.reducerPath]: inoutwardManagerApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authenticationApi.middleware,
      dashboardApi.middleware,
      instituteStaffStudentApi.middleware,
      qvipleDynamicApi.middleware,
      instituteDepartmentApi.middleware,
      postApi.middleware,
      imageApi.middleware,
      searchApi.middleware,
      attendanceApi.middleware,
      settingApi.middleware,
      superDynamicApi.middleware,
      chatAPI.middleware,
      financeDynamicApi.middleware,
      paymentConfigureDynamicApi.middleware,
      CLTApi.middleware,
      joinDynamicApi.middleware,
      sportArtDynamicApi.middleware,
      exportDataDynamicApi.middleware,
      switchAccountApi.middleware,
      departmentDynamicApi.middleware,
      classDynamicApi.middleware,
      subjectDynamicApi.middleware,
      plateformDynamicApi.middleware,
      libraryDynamicApi.middleware,
      eventDynamicApi.middleware,
      careerDynamicApi.middleware,
      admissionNewApi.middleware,
      studentMemberDynamicApi.middleware,
      activityDynamicApi.middleware,
      alumniDynamicApi.middleware,
      hostelApi.middleware,
      transportApi.middleware,

      // here start new optimized code import
      authApi.middleware,
      userMemberApi.middleware,
      financeManagerApi.middleware,
      admissionManagerApi.middleware,
      studentProfileApi.middleware,
      lmsManagerApi.middleware,
      instituteFeedbackApi.middleware,
      libraryManagerApi.middleware,
      departmentManagerApi.middleware,
      mentorManagerApi.middleware,
      classManagerApi.middleware,
      subjectManagerApi.middleware,
      staffApi.middleware,
      studentApi.middleware,
      instituteAutomateManagerApi.middleware,
      storeManagerApi.middleware,
      instituteStudentApi.middleware,
      payrollManagerApi.middleware,
      siteManagerApi.middleware,
      instituteStaffApi.middleware,
      academicDepartmentManagerApi.middleware,
      certificateManagerApi.middleware,
      hostelManagerApi.middleware,
      guideManagerApi.middleware,
      inoutwardManagerApi.middleware
    ),
});

setupListeners(store.dispatch);

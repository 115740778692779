import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../UrlConfig/BaseUrl";
import { tokenVerify } from "../UrlConfig/TokenVerify";

export const lmsManagerApi = createApi({
  reducerPath: "lmsManagerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: tokenVerify,
  }),
  tagTypes: ["Lms_Module"],
  endpoints: () => ({}),
});

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { onlyBaseUrl } from "../UrlConfig/BaseUrl";
import { tokenVerify } from "../UrlConfig/TokenVerify";

export const instituteStudentApi = createApi({
  reducerPath: "instituteStudentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: onlyBaseUrl,
    prepareHeaders: tokenVerify,
  }),
  //   tagTypes: ["Lms_Module"],
  endpoints: () => ({}),
});

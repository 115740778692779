import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrlV1 } from "../UrlConfig/BaseUrl";
import { tokenVerify } from "../UrlConfig/TokenVerify";

export const mentorManagerApi = createApi({
  reducerPath: "mentorManagerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrlV1,
    prepareHeaders: tokenVerify,
  }),
  // tagTypes: ["Tab_Manage"],
  endpoints: () => ({}),
});
// providesTags: ["Finance_Internal_Fees"],
// invalidatesTags: ["Finance_Internal_Fees"],

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { onlyBaseUrl } from "../UrlConfig/BaseUrl";
import { tokenVerify } from "../UrlConfig/TokenVerify";

export const studentProfileApi = createApi({
  reducerPath: "studentProfileApi",
  baseQuery: fetchBaseQuery({
    baseUrl: onlyBaseUrl,
    prepareHeaders: tokenVerify,
  }),
  tagTypes: ["Admission_Fees", "Student_Profile"],
  endpoints: () => ({}),
});

// export const onlyBaseUrl = "http://localhost:8080/api";
// export const localBaseUrl = "http://localhost:8080/api/v2";
// export const baseUrl = "http://localhost:8080/api/v2";
// export const baseUrlV1 = "http://localhost:8080/api/v1";
// export const imageShowUrl = "http://localhost:8080/api/v1/all-images";
// export const automateBaseUrl = "http://localhost:8080/api/v2";

// export const onlyBaseUrl = "https://api.qviple.com/api";
// export const baseUrl = "https://api.qviple.com/api/v2";
// export const localBaseUrl = "https://api.qviple.com/api/v2";
// export const baseUrlV1 = "https://api.qviple.com/api/v1";
// export const imageShowUrl =
//   "https://qviple-development.s3.ap-south-1.amazonaws.com";

// export const onlyBaseUrl = "http://44.197.120.176/api/api";
// export const baseUrl = "http://44.197.120.176/api/api/v2";
// export const localBaseUrl = "http://44.197.120.176/api/api/v2";
// export const baseUrlV1 = "http://44.197.120.176/api/api/v1";
// export const imageShowUrl = "http://44.197.120.176/api/api/v1/all-images";
// export const pdfCdnUrl =
//   "https://qviple-development.s3.ap-south-1.amazonaws.com";
// export const daybookBaseUrl =
//   "https://qviple-development.s3.ap-south-1.amazonaws.com";

// export const baseUrl = "http://54.237.4.164/api/api/v2";
// export const baseUrlV1 = "http://54.237.4.164/api/api/v1";
// export const imageShowUrl = "http://54.237.4.164/api/api/v1/all-images";

// export const onlyBaseUrl = "https://qviple.com/api";
// export const baseUrl = "https://qviple.com/api/v2";
// export const baseUrlV1 = "https://qviple.com/api/v1";
// export const imageShowUrl = "https://d3dqpu54js2vfl.cloudfront.net";
// export const pdfCdnUrl = "https://qviple-dev.s3.ap-south-1.amazonaws.com";
// export const localBaseUrl = "https://qviple.com/api/v2";
// export const daybookBaseUrl = "https://d3dqpu54js2vfl.cloudfront.net";

export const onlyBaseUrl = "https://api.qviple.com/api";
export const baseUrl = "https://api.qviple.com/api/v2";
export const baseUrlV1 = "https://api.qviple.com/api/v1";
export const imageShowUrl = "https://d3dqpu54js2vfl.cloudfront.net";
export const pdfCdnUrl = "https://qviple-dev.s3.ap-south-1.amazonaws.com";
// export const localBaseUrl = "https://api.qviple.com/api/v2";
export const daybookBaseUrl = "https://d3dqpu54js2vfl.cloudfront.net";

export const automateBaseUrl = "http://52.203.216.121/api/api/v2";
export const cdnUrl = "https://d3dqpu54js2vfl.cloudfront.net";

// export const baseUrl = "http://52.23.152.125/api/api/v2";
// export const baseUrlV1 = "http://52.23.152.125/api/api/v1";
// export const imageShowUrl = "http://44.197.120.176/api/api/v1/all-images";
